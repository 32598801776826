<template>
	<div>
		<div class="wrapper">
			<v-container
				class="pa-0"
				fluid
			>
				<v-row
					v-if="loading"
					class="ma-0 loading_area"
				>
					<v-col
						class="pa-0"
						cols="12"
					>
						<CommonLoaderCircle01 />
					</v-col>
				</v-row>
				<v-row
					v-else
					class="ma-0"
				>
					<v-col
						md="8"
						sm="12"
						class="pa-0 px-5 px-md-5 mt-7 mt-md-10 contents_table layout_align"
					>
						<!-- 제목 -->
						<HomeHousewarmingHeader
							:items="house3dItem"
							:type-title="common_title"
						/>

						<!-- 컨텐츠 -->
						<v-col
							cols="12"
							class="body pa-0 pt-7 pt-md-7"
							align="center"
						>
							<v-col class="pa-0 editor_content">
								<!-- 사례 정보 -->
								<HomeHousewarmingInfoBox :items="house3dItem" />

								<!-- 비주얼영역 -->
								<!--<v-img
							eager
							width="100%"
							:src="house3dItem.image"
						/>-->

								<!-- 키워드 태그영역 -->
								<HomeProductDetailTags
									:items="house3dItem.tags"
									class="tag_area bg_tr"
								/>

								<!-- 3D집들이 지원 -->
								<HomeProductDetail3D
									:image="house3dItem.image"
									:link="house3dItem.image_3durl"
								/>

								<!-- <CommonButtonsButton02
									name="맞춤견적 신청"
									class-name="btn_large"
									color="#42883d"
									@click="goToInquire"
								/> -->

								<!-- 에디터 영역 입니다 -->
								<v-card
									v-if="house3dItem.description"
									width="100%"
									min-height="500"
									elevation="0"
									class="ma-0 pa-0 mb-5"
								>
									<CommonEditorQuillViewerTile
										class="py-10"
										:html="house3dItem.description"
									/>
								</v-card>
							</v-col>
						</v-col>
					</v-col>
					<v-col
						v-if="getWidth > 960"
						md="4"
						sm="0"
						class="pa-0 pl-lg-10 pl-5 pr-5 pr-md-5 mt-7 mt-md-10 contents_table layout_align"
					>
						<div
							class="fixed-tabs-bar"
							:class="scrollUp ? 'up' : ''"
						>
							<HomeInteriorPriceDetailinquireCommonForm
								:items="house3dItem"
								:cont-item="contProduct"
							/>
						</div>
					</v-col>
				</v-row>
			</v-container>
			<!--모바일 팝업-->
			<div
				v-if="getWidthMobile"
				class=""
			>
				<div class="all_view">
					<v-img
						eager
						height="22"
						alt="간편상담"
						:src="require(`@/assets/img/estimate/ico_rapid_consult.svg`)"
					/>
					<span
						class="my-0"
						@click="openConsult"
					>
						간편상담
					</span>
				</div>
			</div>
			<div
				v-if="getWidthMobile"
				class="mo_consultWrap"
			>
				<div
					v-if="popupDialog"
					class="popWrap"
				>
					<!-- <p class="">asdf <span @click="closeConsult">닫기</span></p> -->
					<div class="vlistitem_dumy">
						<v-row class="px-4">
							<!-- <v-list-item-title>간편상담 신청하기</v-list-item-title> -->
							<v-col class="contAsk">간편상담 신청하기</v-col>
							<v-col
								class="pa-0 pr-0"
								align-self="center"
								style="text-align: right"
							>
								<v-btn
									icon
									text
									@click="closeConsult"
								>
									<v-icon color="#666">mdi-window-close</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</div>
					<v-divider></v-divider>
					<HomeInteriorPriceDetailinquireCommonForm
						:items="house3dItem"
						:cont-item="contProduct"
						:mobile-state="mobileState"
					/>
				</div>
			</div>
		</div>
		<div class="recommendZone">
			<div class="wrapper">
				<v-row>
					<v-col>
						<!-- 3D 집들이 -->
						<v-col
							v-if="house3dShortItems"
							cols="12"
							align="center"
							class="pa-0 pt-5"
						>
							<v-card class="feature_tit bc_tr mb-8">
								<p>3D 집들이</p>
							</v-card>
							<v-col
								cols="11"
								md="12"
								class="pa-0"
							>
								<div class="card_align">
									<CommonCardMenuGroup05
										:menu-type="'3d'"
										:card-menu="house3dShortItems"
										@submit="onClickPushLink"
									/>
								</div>
							</v-col>
						</v-col>

						<!-- 전문가 집들이 -->
						<v-col
							v-if="specialShortItems"
							cols="12"
							align="center"
							class="pa-0 pt-5 mt-5"
						>
							<v-card class="feature_tit bc_tr mb-8">
								<p>전문가 집들이</p>
							</v-card>
							<v-col
								cols="11"
								md="12"
								class="pa-0"
							>
								<div class="card_align">
									<CommonCardMenuGroup05
										:menu-type="'special'"
										:card-menu="specialShortItems"
										@submit="onClickPushLink"
									/>
								</div>
							</v-col>
						</v-col>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import common from '@/mixins/common'
import ad_kakao from '@/mixins/ad/kakao'
import ad_naver from '@/mixins/ad/naver'
import ad_mobion from '@/mixins/ad/mobion'

export default {
	name: 'Housewarming3DDetail',
	metaInfo() {
		return {
			title: `${this.house3dItem.title} | 동화자연마루`,
			meta: [
				{
					vmid: 'title',
					property: 'og:title',
					content: `${this.house3dItem.title} | 동화자연마루`,
				},
				{
					vmid: 'description',
					property: 'og:description',
					content: '진짜 집에 온것 처럼 720˚ VR 3D집들이를 떠나보세요.',
				},
				{
					vmid: 'url',
					property: 'og:url',
					content: `${process.env.VUE_APP_BASIC_SERVER}/housewarming/3d`,
				},
				{
					vmid: 'og:image',
					property: 'og:image',
					content: `${this.house3dItem.image}`,
				},
			],
		}
	},
	mixins: [common, ad_kakao, ad_naver, ad_mobion],
	data: () => ({
		house3dItem: {},
		house3dShortItems: [],
		specialShortItems: [],
		loading: false,
		contProduct: null,
		scrollUp: true,
		popupDialog: false,
		mobileState: false,
		getWidthMobile: false,
	}),
	computed: {
		...mapGetters([
			// 제품 item
			'HOME_GET_GET_PRODUCT_ITEMS',
			'APP_GET_APP_WIDTH',
		]),
		getQuery() {
			return this.$route.params.id
		},
		getWidth() {
			return this.APP_GET_APP_WIDTH
		},
	},
	watch: {
		getQuery() {
			this.getData()
		},
		getWidth(val) {
			this.mobchange(val)
			if (val > 960) {
				this.handleComputedDataChange(false, 'popstate')
			} else {
				this.handleComputedDataChange(true, 'popstate')
			}
		},
		popupDialog(val) {
			this.handleComputedDataChange(val, 'popstate')
		},
	},
	created() {
		this.getData()
		window.addEventListener('scroll', this.handleScroll)
		//this.getToInquire()
	},
	deactivated() {
		window.removeEventListener('scroll', this.handleScroll)
		this.handleComputedDataChange(false, 'popstate')
	},
	mounted() {
		// ad_kakao page
		this.ad_kakao_pageScript('3d_detail')
		// ad_naver page
		this.ad_naver_changeScript(3, 1)
		// ad_mobion page
		this.ad_mobion_page_convertion('page_view_3d_detail')
		this.mobchange(this.getWidth)
	},
	destroyed() {
		this.handleComputedDataChange(false, 'popstate')
	},
	methods: {
		...mapMutations(['HOME_MU_INTERIOR_SELECT_PRODUCT']),
		...mapActions([
			'HOME_ACT_GET_PRODUCT_ITEMS',
			'HOME_ACT_HOUSEWARMING_HOUSE_3D',
			'HOME_ACT_HOUSEWARMING_SPECIAL',
		]),
		async getData() {
			this.loading = true
			// 3D집들이 상세 정보
			await this.HOME_ACT_HOUSEWARMING_HOUSE_3D({ method: 'detail', query: this.getQuery })
				.then(res => {
					this.parseData(res)
					// 추가입력사항
					let item = res
					item[0].type = '3D집들이관리'
					item[0].contents = {
						name: this.house3dItem.title,
						id: this.getQuery,
					}
					this.contProduct = item[0]
				})
				.catch(() => {
					this.loading = false
				})
			// 3D집들이 간략정보
			await this.HOME_ACT_HOUSEWARMING_HOUSE_3D({ method: '3D_pop', query: this.getQuery })
				.then(res => {
					this.house3dShortItems = res
				})
				.catch(() => {
					this.loading = false
				})
			// 전문가집들이 간략정보
			await this.HOME_ACT_HOUSEWARMING_SPECIAL({ method: 'special_pop', query: this.getQuery })
				.then(res => {
					this.specialShortItems = res
					this.loading = false
				})
				.catch(() => {
					this.loading = false
				})
		},
		parseData(data) {
			let parser = JSON.parse(JSON.stringify(data[0]))

			parser.image = parser.image_url ? parser.image_url : require('@/assets/img/product/img_3d.jpg')
			parser.store = parser.business_name

			console.log('parser', parser)

			this.house3dItem = parser
		},
		async goToInquire() {
			// 메인 제품 list에서 선택 상품 찾기
			if (this.HOME_GET_GET_PRODUCT_ITEMS.length === 0) await this.HOME_ACT_GET_PRODUCT_ITEMS({})
			let item = this.HOME_GET_GET_PRODUCT_ITEMS.find(item => item.goods_code === this.house3dItem.goods_code)
			// 추가입력사항
			item.type = '3D집들이관리'
			item.contents = {
				name: this.house3dItem.title,
				id: this.getQuery,
			}

			this.HOME_MU_INTERIOR_SELECT_PRODUCT(item)
			this.$router.push('/interior/price/inquire').catch(() => {})
		},
		// async getToInquire() {
		// 	if (this.HOME_GET_GET_PRODUCT_ITEMS.length === 0) await this.HOME_ACT_GET_PRODUCT_ITEMS({})
		// 	let item = this.HOME_GET_GET_PRODUCT_ITEMS.find(item => item.goods_code === this.house3dItem.goods_code)

		// 	console.log('item', item)
		// 	// 추가입력사항
		// 	item.type = '3D집들이관리'
		// 	item.contents = {
		// 		name: this.house3dItem.title,
		// 		id: this.getQuery,
		// 	}
		// 	this.contProduct = item
		// },
		onClickPushLink(menu) {
			console.log('menu', menu)
			// 기본 3d
			let link = `/housewarming/3d/detail/${menu.hw_id}`
			if (menu.link === 'special') link = `/housewarming/specialist/detail/${menu.exphw_id}`

			this.$router.push(link).catch(() => {})
		},
		handleScroll() {
			// 스크롤 방향 체크하기
			const scrollTop = window.scrollY || window.pageYOffset
			if (scrollTop > this.lastScrollTop) {
				this.scrollUp = false
			} else if (scrollTop < this.lastScrollTop) {
				this.scrollUp = true
			}
			this.lastScrollTop = scrollTop
		},

		//간편견적 모바일
		openConsult() {
			this.popupDialog = true
			this.mobileState = true
		},
		closeConsult() {
			this.popupDialog = false
			this.mobileState = false
			//if (e.target.className === 'closeBtn') this.popupDialog = false
		},
		mobchange(val) {
			if (val > 960) {
				this.getWidthMobile = false
			} else {
				this.getWidthMobile = true
			}
		},
		handleComputedDataChange(newVal, state) {
			var htmlElement = document.querySelector('html')

			if (newVal === true && state === 'popstate') {
				htmlElement.style.overflow = 'hidden'
			}
			if (newVal === false && state === 'popstate') {
				htmlElement.style.overflow = 'visible'
			}
			if (newVal === false && state === 'popstate') {
				htmlElement.style.overflow = 'visible'
			}
		},
	},
}
</script>

<style scoped lang="scss">
.fixed-tabs-bar {
	position: -webkit-sticky;
	position: sticky;
	top: 90px;
	z-index: 2;
	background-color: #fff;
	&.up {
		top: 117px;
	}
}
.vlistitem_dumy {
	align-items: center;
	align-self: center;
	display: flex;
	flex-wrap: wrap;
	flex: 1 1;
	overflow: hidden;
	padding: 12px 0;
	.contAsk {
		font-weight: $fw_bold;
		font-size: $font_xl;
		color: $color_font;
	}
}

.tag_area {
	margin: 0 !important;
}

.recommendZone {
	background: #f8f8f8;
	margin-top: 50px;
	bottom: -38px;
	position: relative;
	padding: 40px 0;
}

.popWrap {
	position: fixed;
	z-index: 100;
	height: 100%;
	top: 0px;
	width: 100%;
	background: #fff;
}
.all_view {
	display: flex;
	position: fixed;
	align-items: center;
	bottom: 101px;
	right: 20px;
	z-index: 10;
	background-color: #00ce7d;
	color: #fff;
	padding: 5px 10px;
	border-radius: 99px;
	box-shadow: 0px 2px 8px 0px #00000026;
	&:hover {
		cursor: pointer;
	}
}
</style>
